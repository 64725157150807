import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploadService } from '../_services/fileupload.service';
import { ProfileService } from '../_services/profile.services';
import { fabric } from "fabric";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  templateUrl: './avatar-framer.component.html',
  styleUrls: ['./avatar-framer.scss']
})
export class AvatarFramerComponent implements OnInit {
  imageDownload: any

  img02URL = '/assets/images/avatar-frame.png';
  canvas: any
  width: any = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  height: any = (window.innerHeight > 0) ? window.innerHeight : screen.height;
  defaultSize = 500;
  startX: number = 0
  startY: number = 0

  imgHeightDownload
  imgWidthDownload

  constructor(private router: Router, private profileService: ProfileService, private fileUploadService: FileUploadService,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.canvas = new fabric.Canvas('c', {
      width: 500, height: 500
    });
    var ratio = this.canvas.getWidth() / this.canvas.getHeight();
    if ((this.width / this.height) > ratio) {
      this.width = this.height * ratio;
    } else {
      this.height = this.width / ratio - 100;
      this.width = this.width / ratio - 100;
    }
    var scale = this.width / this.canvas.getWidth();
    var zoom = this.canvas.getZoom();
    zoom *= scale;
    this.canvas.setDimensions({
      width: this.width,
      height: this.width
    });
    this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    this.canvas.setOverlayImage(this.img02URL, () => {
      this.canvas.overlayImage && this.canvas.overlayImage.scaleToWidth(this.width)
      this.canvas.renderAll()
    }, {
      // Needed to position overlayImage at 0/0
      originX: 'left',
      originY: 'top',
      crossOrigin: 'anonymous'
    });
    this.resizeCanvas()
  }

  chooseFileImage(event) {
    const file: File = event.target.files[0];
    this.img02URL = URL.createObjectURL(file);
    const outerCanvasContainer = document.getElementsByClassName("canvas-container")[0] as HTMLElement;
    var zoom = this.canvas.getZoom();
    fabric.Image.fromURL(this.img02URL, (oImg) => {
      oImg.set({
        lockMovementX: false,
        lockMovementY: false,
        lockScalingX: false,
        lockScalingY: false,
        lockRotation: false,
        selectable: true,
        centeredRotation: true,
        centeredScaling: true,
        lockSkewingX: true,
        lockSkewingY: true,
        originX: 'center',
        originY: 'center',
        hasControls: false
      });
      console.log(this.canvas.getHeight())
      oImg.scaleToHeight(this.canvas.getHeight() / zoom);
      oImg.scaleToWidth(this.canvas.getHeight() / zoom);
      this.canvas.centerObject(oImg);
      this.canvas.add(oImg);
      this.canvas.renderAll();
      
      document.getElementById('scaleImage').addEventListener('input', value => {
        oImg.scaleX = value.target['value'] / 100;
        oImg.scaleY = value.target['value'] / 100;

        console.log(oImg)
        this.canvas.requestRenderAll();
      }, false);
      document.getElementById('angleImage').addEventListener('input', value => {
        const valued = parseInt(value.target['value'], 36);
        const angleParse = valued > 360 ? 360 : valued < 0 ? 0 : valued;
        this.canvas.item(0)['centeredRotation'] = true;
        oImg.set({
          angle: angleParse,
          // centeredRotation: true
        }).setCoords();
        this.canvas.renderAll();
      }, false);
    });

  }

  async downloadImage() {
    const a = document.createElement('a');
    var multiplier = 1000 / this.width
    console.log("multiplier " + multiplier)
    this.canvas.renderAll();
    a.href = this.canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: multiplier
    });
    a.download = 'avatar-framer.png';
    document.body.appendChild(a);
    a.click();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeCanvas();
  }

  resizeCanvas() {
    const outerCanvasContainer = document.getElementsByClassName("canvas-container")[0] as HTMLElement;
    const modalBody = document.getElementsByClassName("form-group")[0] as HTMLElement;
    console.log(outerCanvasContainer.clientWidth)
    if (outerCanvasContainer.clientWidth > 700) {
      outerCanvasContainer.style.width = "700";
      outerCanvasContainer.style.height = "700"
    } else {
      outerCanvasContainer.style.width = "auto";
      outerCanvasContainer.style.height = "".concat(outerCanvasContainer.clientWidth + "", "px");
    }
    if (modalBody != undefined){
      this.imgWidthDownload = modalBody.clientWidth -40;
      this.imgHeightDownload = modalBody.clientWidth -40;
    }
  }

  removeObject() {
    this.canvas.getActiveObjects().forEach((obj) => {
      this.canvas.remove(obj)
    });
    this.canvas.discardActiveObject().renderAll();
  }

  openModal( exampleModalContent ) {
    const windowWidth = window.screen.width
    if(windowWidth >= 576){
      this.imgWidthDownload = 466;
      this.imgHeightDownload = 466;
    } else {
      this.imgWidthDownload = windowWidth - 53;
      this.imgHeightDownload = windowWidth - 53;
    }
    var multiplier = 1000 / this.width
    this.imageDownload = this.canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: multiplier
    });
    this.modalService.open( exampleModalContent, { size : 'md' } );
  }

}
