import { Component, HostListener, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/_models/profile';
import { FileUploadService } from '../_services/fileupload.service';
import { ProfileService } from '../_services/profile.services';
import * as htmlToImage from 'html-to-image';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fabric } from "fabric";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../common/app.constants';

@Component({
  selector: 'app-convertImg',
  templateUrl: './convertImg.component.html',
  styleUrls: ['./convertImg.component.scss'],
})
export class ConvertImgComponent implements OnInit {

  subtitle: any
  profile: Profile = {
    content: '',
    postion: '',
    fullName: ''
  }
  profileUsers: Profile[] = []
  name = 'Angular';
  file: any = null;
  previewUrl: any = '';
  fileToUpload: any;
  imageUrl: any;
  fullName: any
  content: any
  form: FormGroup;
  imageDownload: any
  imgHeightDownload
  imgWidthDownload
  overlayKyvong = '/assets/images/bg_overlay.png';
  backgroudKyvong = '/assets/images/background.png';
  canvas: any;
  width: any = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  height: any = (window.innerHeight > 0) ? window.innerHeight : screen.height;
  originBackend = AppConstants.API_URL2;


  constructor(private formBuilder: FormBuilder, private router: Router, private profileService: ProfileService,
    private modalService: NgbModal, private fileUploadService: FileUploadService) {
    // this.getListAllProfile();
  }

  ngOnInit() {
    this.canvas = new fabric.Canvas('canvas-kyvong', {
      width: 2000, height: 2000
    });
    var ratio = this.canvas.getWidth() / this.canvas.getHeight();
    if ((this.width / this.height) > ratio) {
      this.width = this.height * ratio;
    } else {
      this.height = this.width / ratio - 100;
      this.width = this.width / ratio - 100;
    }
    var scale = this.width / this.canvas.getWidth();
    var zoom = this.canvas.getZoom();
    zoom *= scale;
    this.canvas.setDimensions({
      width: this.width,
      height: this.width
    });
    this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    fabric.Image.fromURL(this.backgroudKyvong, (img) => {
      img.set({
        originX: 'left',
        originY: 'top',
        crossOrigin: 'anonymous',
      }).scale(0.9765)
      this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));
    });

    this.canvas.setOverlayImage(this.overlayKyvong, () => {
      this.canvas.overlayImage && this.canvas.overlayImage.scaleToWidth(this.width)
      this.canvas.renderAll()
    }, {
      // Needed to position overlayImage at 0/0
      originX: 'left',
      originY: 'top',
      crossOrigin: 'anonymous'
    });
    this.resizeCanvas()
    this.addText();
  }

  // getListAllProfile() {
  //   this.profileService.getAllProfile().subscribe(
  //     response => {
  //       this.profileUsers = response
  //       this.profileUsers.sort((a, b) => b.id - a.id)
  //     }, err => {

  //     }
  //   )
  // }

  resizeCanvas() {
    const outerCanvasContainer = document.getElementsByClassName("canvas-container")[0] as HTMLElement;
    if (outerCanvasContainer.clientWidth > 700) {
      outerCanvasContainer.style.width = "510";
      outerCanvasContainer.style.height = "510"
    } else {
      outerCanvasContainer.style.width = "auto";
      outerCanvasContainer.style.height = "".concat(outerCanvasContainer.clientWidth + "", "px");
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeCanvas();
  }

  fileProgress(fileInput: any) {
    const file = <File>fileInput.target.files[0];
    const imageUpload = URL.createObjectURL(file);
    var zoom = this.canvas.getZoom();
    fabric.Image.fromURL(imageUpload, (oImg) => {
      oImg.scaleToWidth(600);
      oImg.set({
        left: 400,
        top: 100,
        cornerStyle: "circle",
        cornerColor: "red",
        cornerStrokeColor: "red",
        borderColor: "red",
        cornerSize: 15,

      });
      document.getElementById('scaleImage').addEventListener('input', value => {
        oImg.scaleX = value.target['value'] / 100;
        oImg.scaleY = value.target['value'] / 100;
        this.canvas.requestRenderAll();
      }, false);
      document.getElementById('angleImage').addEventListener('input', value => {
        const valued = parseInt(value.target['value'], 36);
        const angleParse = valued > 360 ? 360 : valued < 0 ? 0 : valued;
        this.canvas.item(0)['centeredRotation'] = true;
        oImg.set({
          angle: angleParse
        }).setCoords();
        this.canvas.renderAll();
      }, false);
      this.canvas.add(oImg);
      this.canvas.renderAll();
    });
  }

  addText() {
    var textboxHoTen = new fabric.Textbox(this.profile.fullName, {
      left: 40,
      top: 550,
      width: 1100,
      fontSize: 80,
      fontFamily: 'SVN-Diablo',
      cornerStrokeColor: '',
      fontWeight: 700,
      fill: '#ffffff',
      shadow:'2px 2px black',
      type: 'text',
      textAlign: 'center',
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      selected: false,
      selectable: false,
      moveCursor: 'none',
      hoverCursor: 'none'
    });
    document.getElementById('floatFullName').addEventListener('input', (event) => {
      textboxHoTen.set({
        text: event.target['value'],
        type: "text"
      })
      this.canvas.requestRenderAll();
      this.exist = false;
    }, false);
    this.canvas.add(textboxHoTen);
  }

  onSubmit(): void { }

  removeObject() {
    this.canvas.getActiveObjects().forEach((obj) => {
      this.canvas.remove(obj)
    });
    this.canvas.discardActiveObject().renderAll();
  }

  openModal(exampleModalContent) {
    const windowWidth = window.screen.width
    if (windowWidth >= 576) {
      this.imgWidthDownload = 466;
      this.imgHeightDownload = 466;
    } else {
      this.imgWidthDownload = windowWidth - 53;
      this.imgHeightDownload = windowWidth - 53;
    }
    var multiplier = 2000 / this.width
    this.imageDownload = this.canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: multiplier
    });
    this.modalService.open(exampleModalContent, { size: 'md' });
  }

  async downloadImage() {
    const a = document.createElement('a');
    var multiplier = 2000 / this.width
    console.log("multiplier " + multiplier)
    this.canvas.renderAll();
    a.href = this.canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: multiplier
    });
    a.download = 'ky-vong-dai-hoi.png';
    document.body.appendChild(a);
    a.click();
  }

  exist: boolean = false;

  saveImageDB(update: boolean) {
    var multiplier = 2000 / this.width
    const base64 = this.canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: multiplier
    });
    var block = base64.split(";");
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
    var blob = this.b64toBlob(realData, contentType, 0);
    const imageName = 'banner_store.png';
    const imageFile = new File([blob], imageName);
    const formData = new FormData();

    // this.profileService.addProfile(this.profile, update).subscribe(
    //   response => {
    //     if (response.error) {
    //       this.exist = true;
    //     } else {
    //       let id = response.resultData.id;
    //       formData.append('profileUserId', id);
    //       formData.append('upload', imageFile);
    //       this.fileUploadService.uploadImage(formData).subscribe(
    //         response => {
    //           // this.getListAllProfile()
    //         }, err => {
    //         }
    //       )
    //       this.modalService.dismissAll();
    //     }
    //   }, err => {
    //   }
    // )
    if (update) {
      this.downloadImage()
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
