// using for localhost
// export class AppConstants {
//     private static API_BASE_URL = "http://192.168.0.108:9097/";
//     private static API_BASE_URL2 = "http://192.168.0.108:9097";
//     public static API_URL = AppConstants.API_BASE_URL + "api/";
//     public static API_URL2 = AppConstants.API_BASE_URL2;
// }


// using for Production
export class AppConstants {
    private static API_BASE_URL = "https://taoanh.tinhdoandongnai.com:9097/";
    private static API_BASE_URL2 = "https://taoanh.tinhdoandongnai.com:9097";
    public static API_URL = AppConstants.API_BASE_URL + "api/";
    public static API_URL2 = AppConstants.API_BASE_URL2;
}